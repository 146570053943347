<template>
  <div class="ChatBackgroundsBox__main-container">
    <h3 class="ChatBackgroundsBox__title">Chat Backgrounds</h3>
    <Popup v-if="idToRemove" @hidePop="hidePop" @remove="remove" />
    <Form
      v-if="isActiveForm"
      :active-background-data="activeBackgroundData"
      @goToList="goToList"
    />
    <div v-else class="ChatBackgroundsBox__center-container">
      <div class="ChatBackgroundsBox__header">
        <div class="ChatBackgroundsBox__header-buttons-container">
          <button
            v-for="status in statusTypes"
            :key="status"
            :class="{ active: status === selectedType }"
            type="button"
            class="ChatBackgroundsBox__header-button"
            @click="selectType(status)"
          >
            {{ status }}
          </button>
        </div>
        <button
          type="button"
          class="ChatBackgroundsBox__header-button"
          @click="isActiveForm = true"
        >
          Add new background
        </button>
      </div>
      <div class="ChatBackgroundsBox__boxes-container">
        <div
          v-for="backgroundData in chatBackgroundsList"
          :key="backgroundData.id"
          class="ChatBackgroundsBox__background-box"
        >
          <img
            :src="backgroundData.imageUrl"
            class="ChatBackgroundsBox__image"
            alt="background"
          />
          <span class="ChatBackgroundsBox__text">
            {{ backgroundData.type }}
          </span>
          <button
            type="button"
            class="ChatBackgroundsBox__button-edit"
            @click="openForm(backgroundData)"
          >
            Edit
          </button>
          <button
            type="button"
            class="ChatBackgroundsBox__button-remove"
            @click="showPopup(backgroundData.id)"
          >
            Remove
          </button>
        </div>
        <infinite-loading
          ref="infiniteLoading"
          spinner="spiral"
          direction="top"
          @infinite="infiniteHandler"
        >
          <div slot="no-more">
            <div class="ChatBackgroundsBox__background-box">
              No more results to show.
            </div>
          </div>
          <div slot="no-results">
            <div class="ChatBackgroundsBox__background-box">
              No more results to show.
            </div>
          </div>
        </infinite-loading>
      </div>
    </div>
  </div>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading';
import { mapGetters, mapActions } from 'vuex';
import Form from './Form.vue';
import Popup from './Popup.vue';
export default {
  name: 'ChatBackgroundsBox',

  components: {
    Form,
    Popup,
    InfiniteLoading,
  },

  data: () => ({
    selectedType: 'ALL',
    isActiveForm: false,
    activeBackgroundData: null,
    idToRemove: '',
    statusTypes: ['ALL', 'MOBILE', 'WEB'],
  }),

  computed: {
    ...mapGetters(['chatBackgroundsList', 'chatBackgroundsNextToken']),
  },

  created() {
    this.removeBackgroundsListData();
    this.getBackgrounds(this.selectedType);
  },

  methods: {
    ...mapActions([
      'getBackgrounds',
      'removeBackground',
      'removeBackgroundsListData',
    ]),

    async selectType(type) {
      await this.removeBackgroundsListData();
      this.getBackgrounds(type);
      this.selectedType = type;
    },
    goToList() {
      this.isActiveForm = false;
      setTimeout(() => {
        this.getBackgrounds(this.selectedType);
      }, 100);
      this.activeBackgroundData = null;
    },
    openForm(data) {
      this.isActiveForm = true;
      this.activeBackgroundData = data;
    },
    showPopup(id) {
      this.idToRemove = id;
    },
    hidePop() {
      this.idToRemove = '';
    },
    remove() {
      this.removeBackground(this.idToRemove);
      this.idToRemove = '';
    },
    infiniteHandler($state) {
      if (!this.chatBackgroundsNextToken) {
        $state.complete();
        return;
      }
      this.getBackgrounds(this.selectedType).then((data) => {
        data.nextToken ? $state.loaded() : $state.complete();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/stylesheets/components/chat-backgrounds/Box.scss';
</style>
